import { GoldOutlined } from '@ant-design/icons';
import {
  actionConstants,
  appConstants,
  flagConstants,
  resourceConstants,
  roleConstants,
  guideConstants,
  commonConstants,
} from 'constants/index';
import { PURCHASING_PATHS } from 'constants/paths';
import { commonHelpers } from 'helpers';
import { t } from 'helpers/i18n';
import { getSrc } from 'helpers/url';
import { lazy } from 'react';
import configHelpers from 'helpers/config';

const { getCommonConfig } = configHelpers;
const {
  baseSupplyChainUrl,
  baseSellerCenterV2Url,
  baseSrmUrl,
  baseErpUrl,
  baseRebateUrl,
} = getCommonConfig();

const { SUPPLY, WMS, REBATE_STAFF_BFF, STAFF_BFF } = appConstants;
const {
  WRITER,
  READER,
  PHONGVU,
  POSTMART_MANAGER,
  POSTMART_SALESMAN,
  TAKASHIMAYA,
  SOIBIEN,
  SOIBIEN_SUPPLYCHAIN,
} = roleConstants;
const {
  CLEARANCE_REQUEST,
  PROGRAM: REBATE_PROGRAM,
  RULE: REBATE_RULE,
  STOCK_REQUEST,
  SUPPLIER_QUOTATIONS,
  CLEARANCE_TYPE,
  SUPPLY_STOCK_PLAN,
  PURCHASE_ORDER,
  PENDING_ORDER,
  GOODS_COMING_TRACKING,
  STOCK_TRANSFER,
  INVENTORY,
  SITE_LEAD_TIME,
  SUPPLIER_MANAGEMENT,
  PURCHASE_ORDER_RETURN,
  AUDIT_LOG,
  INVENTORY_TRACKING,
  EXPORT_HISTORY,
  MANUFACTURE,
  DEMAND_PLANNING,
  SUPPLY_SOURCE_WARNING,
  LEAD_TIME_WARNING,
  STOCK_REQUEST_DAILY,
} = resourceConstants;
const {
  READ,
  CREATE,
  UPDATE,
  READ_IMBALANCED_STOCKS,
  UPDATE_STOCK_REQUEST,
  QUICK_CREATE,
  VIEW_AUDIT_LOG_SC,
  CREATE_IMPORT,
  READ_CLEARANCE_REQUEST,
  READ_BOM,
} = actionConstants;
const { SERVICE_CODE, PAGE_CODE, USER_GUIDE_CODE } = guideConstants;
const { HIDE_SELLER_SWITCH_AND_BACK_BUTTON_EXPORT_TYPES } = commonConstants;
const { FLAG_KEYS } = flagConstants;
const { permission } = commonHelpers;

// Pages
const IFrameContainer = lazy(() => import('containers/shared/IFrameContainer'));
const IFrameAuditLog = lazy(() => import('containers/shared/IFrameAuditLog'));

const purchasingRoutes = [
  // Level 1 menu
  {
    path: '/purchasing',
    name: t('Purchasing.Root'),
    icon: GoldOutlined,
    children: [
      '/purchasing/stock-requests',
      '/purchasing/goods-coming-tracking',
      getSrc(baseSrmUrl, PURCHASING_PATHS.SupplierPriceList),
      '/purchasing/purchase-order',
      '/purchasing/por',
      '/purchasing/supply-stock-plan',
      '/purchasing/backorder-plans',
      '/purchasing/supplier-management',
      '/purchasing/stock-transfer/create',
      getSrc(baseSrmUrl, PURCHASING_PATHS.BranchManualRequests),
      getSrc(baseSrmUrl, PURCHASING_PATHS.SupplierReturnRequests),
      '/purchasing/rebate',
      '/purchasing/inventory-alert',
      '/purchasing/stock-imbalance-alerts',
      '/purchasing/clearance-types',
      '/purchasing/inventory-tracking',
      '/purchasing/stock-transfer-tracking',
      '/purchasing/manufacture-management',
      '/purchasing/demand-planning',
      '/purchasing/budget-planning',
      '/purchasing/warning',
      '/purchasing/order-management',
    ],
  },
  // Level 2 menus
  {
    exact: true,
    path: '/purchasing/stock-requests',
    name: t('Purchasing.StockRequests'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.StockRequests),
    permissions: [permission(STAFF_BFF, STOCK_REQUEST, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.STOCK_REQUEST,
    },
  },
  {
    exact: true,
    path: '/purchasing/order-management',
    name: t('Purchasing.OrderManagement'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.OrderManagement),
    permissions: [
      permission(STAFF_BFF, STOCK_REQUEST_DAILY, READ),
      permission(STAFF_BFF, STOCK_REQUEST_DAILY, UPDATE),
    ],
    flagKey: FLAG_KEYS.EPIC_OMNI_1375,
  },
  {
    exact: true,
    path: '/purchasing/stock-requests/import-history',
    name: t('ImportHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.StockRequestImportHistory
    ),
    hideTitle: true,
    permissions: [permission(STAFF_BFF, STOCK_REQUEST, UPDATE_STOCK_REQUEST)],
  },
  {
    exact: true,
    path: '/purchasing/goods-coming-tracking',
    name: t('Purchasing.GoodsComingTracking'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.GoodsComingTracking),
    permissions: [permission(STAFF_BFF, GOODS_COMING_TRACKING, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.GOOD_COMMING_TRACKING,
    },
  },
  {
    exact: true,
    path: '/purchasing/stock-requests/pending-orders',
    name: t('Purchasing.PendingOrders'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PendingOrders),
    permissions: [permission(STAFF_BFF, PENDING_ORDER, READ)],
  },
  {
    exact: true,
    path: '/purchasing/stock-requests/pending-orders/import-history',
    name: t('ImportHistory'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.PendingOrdersImportHistory
    ),
    permissions: [permission(STAFF_BFF, PENDING_ORDER, CREATE_IMPORT)],
  },
  {
    exact: true,
    path: getSrc(baseSrmUrl, PURCHASING_PATHS.SupplierPriceList),
    name: t('Purchasing.SupplierPriceList'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_SUPPLYCHAIN),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/purchasing/purchase-order/create',
    name: t('Purchasing.PurchaseOrderCreate'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PurchaseOrderCreate),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER, UPDATE)],
  },
  {
    exact: true,
    path: '/purchasing/purchase-order/:purchaseOrderId/update',
    name: t('Purchasing.PurchaseOrderUpdate'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PurchaseOrderUpdate),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER, UPDATE)],
  },
  {
    exact: true,
    path: '/purchasing/purchase-order/:purchaseOrderId/approval',
    name: t('Purchasing.PurchaseOrderApproval'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.PurchaseOrderApproval
    ),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER, UPDATE)],
  },
  {
    exact: true,
    path: '/purchasing/purchase-order/:purchaseOrderId/clone',
    name: t('Purchasing.PurchaseOrderCreate'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PurchaseOrderClone),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER, UPDATE)],
  },
  {
    exact: true,
    path: '/purchasing/purchase-order/:purchaseOrderId/detail',
    name: t('Purchasing.PurchaseOrderDetail'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PurchaseOrderDetail),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER, READ)],
  },
  {
    exact: true,
    path: '/purchasing/purchase-order',
    name: t('Purchasing.PurchaseOrder'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PurchaseOrder),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.PURCHASE_ORDER,
    },
  },
  {
    exact: true,
    path: '/purchasing/por/create',
    name: t('Purchasing.PurchaseOrderReturnCreate'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.PurchaseOrderReturnCreate
    ),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER_RETURN, UPDATE)],
  },
  {
    exact: true,
    path: '/purchasing/por',
    name: t('Purchasing.PurchaseOrderReturn'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PurchaseOrderReturn),
    permissions: [permission(STAFF_BFF, PURCHASE_ORDER_RETURN, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.SUPPLIER_RETURN_REQUEST,
    },
  },
  {
    exact: true,
    path: '/purchasing/supply-stock-plan',
    name: t('Purchasing.SupplyStockPlan'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.SupplyStockPlan),
    permissions: [permission(STAFF_BFF, SUPPLY_STOCK_PLAN, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.SUPPLY_STOCK_PLAN,
    },
  },
  {
    exact: true,
    path: '/purchasing/supply-stock-plan/upload-history',
    name: t('Purchasing.SupplyStockPlan'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.SupplyStockPlanHistory
    ),
    permissions: [permission(STAFF_BFF, SUPPLY_STOCK_PLAN, READ)],
  },
  {
    exact: true,
    path: '/purchasing/budget-planning',
    name: t('Purchasing.BudgetPlanning'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.BudgetPlanning),
    permissions: [permission(STAFF_BFF, DEMAND_PLANNING, READ)],
    flagKey: FLAG_KEYS.DEMAND_PLANNING,
  },
  {
    exact: true,
    path: '/purchasing/backorder-plans',
    name: t('Purchasing.BackorderPlans'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.BackorderPlans),
    permissions: [permission(SUPPLY, WRITER), permission(SUPPLY, READER)],
    usePermissionPrefix: true,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.BACK_ORDER,
    },
  },
  {
    path: '/purchasing/supplier-management',
    name: t('Purchasing.SupplierManagement'),
    children: [
      getSrc(baseSrmUrl, PURCHASING_PATHS.Suppliers),
      '/purchasing/supplier-management/supplier-po-requirements',
      '/purchasing/supplier-management/price-list',
    ],
  },
  {
    exact: true,
    path: '/purchasing/stock-transfer/create',
    name: t('Purchasing.StockTransferCreate'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.StockTransferCreate),
    permissions: [permission(STAFF_BFF, STOCK_TRANSFER, QUICK_CREATE)],
  },
  {
    exact: true,
    path: '/purchasing/stock-transfer',
    name: t('Purchasing.StockTransfer'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.StockTransfer),
    permissions: [permission(STAFF_BFF, STOCK_TRANSFER, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.CREATE_STOCK_TRANSFER,
    },
  },
  {
    exact: true,
    path: '/purchasing/inventory-tracking',
    name: t('Purchasing.InventoryTracking'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.InventoryTracking),
    permissions: [permission(STAFF_BFF, INVENTORY_TRACKING, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.INVENTORY_TRACKING,
    },
  },
  {
    exact: true,
    path: '/purchasing/inventory-tracking/upload-history',
    name: t('Purchasing.InventoryTracking'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.InventoryTrackingUploadHistory
    ),
    permissions: [permission(STAFF_BFF, STOCK_REQUEST, CREATE)],
  },
  {
    exact: true,
    path: getSrc(baseSrmUrl, PURCHASING_PATHS.BranchManualRequests),
    name: t('Purchasing.BranchManualRequests'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, POSTMART_SALESMAN),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_SUPPLYCHAIN),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: getSrc(baseSrmUrl, PURCHASING_PATHS.SupplierReturnRequests),
    name: t('Purchasing.SupplierReturnRequests'),
    redirectOutside: true,
    needAppendUserId: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_SUPPLYCHAIN),
    ],
    usePermissionPrefix: true,
  },
  {
    path: '/purchasing/rebate',
    name: t('Purchasing.RebatePrograms'),
    children: ['/purchasing/rebate/list', '/purchasing/rebate/create'],
  },
  // Level 3 menus
  {
    exact: true,
    path: getSrc(baseSrmUrl, PURCHASING_PATHS.Suppliers),
    name: t('Purchasing.Suppliers'),
    needAppendUserId: true,
    redirectOutside: true,
    needAppendSellerId: true,
    permissions: [
      permission(WMS, PHONGVU),
      permission(WMS, POSTMART_MANAGER),
      permission(WMS, TAKASHIMAYA),
      permission(WMS, SOIBIEN),
      permission(WMS, SOIBIEN_SUPPLYCHAIN),
    ],
    usePermissionPrefix: true,
  },
  {
    exact: true,
    path: '/purchasing/supplier-management/price-list',
    name: t('Purchasing.PriceList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PriceList),
    permissions: [permission(STAFF_BFF, SUPPLIER_QUOTATIONS, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.SUPPLIER_PRICE,
    },
  },
  {
    exact: true,
    path: '/purchasing/supplier-management/price-list/upload-history',
    name: t('Purchasing.PriceList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.PriceListUploadHistory
    ),
    permissions: [permission(STAFF_BFF, SUPPLIER_QUOTATIONS, READ)],
  },
  {
    exact: true,
    path:
      '/purchasing/supplier-management/price-list/import-supply-status-history',
    hideTitle: true,
    name: t('Purchasing.PriceList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.PriceListImportSupplyStatusHistory
    ),
    permissions: [permission(STAFF_BFF, SUPPLIER_QUOTATIONS, READ)],
  },
  {
    exact: true,
    path: '/purchasing/supplier-management/price-list/create',
    name: t('Purchasing.AddNewPriceList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PriceListCreate),
    permissions: [permission(STAFF_BFF, SUPPLIER_QUOTATIONS, UPDATE)],
  },
  {
    exact: true,
    path: '/purchasing/supplier-management/price-list/:id',
    name: t('Purchasing.UpdatePriceList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.PriceListDetail),
    permissions: [permission(STAFF_BFF, SUPPLIER_QUOTATIONS, READ)],
  },
  {
    exact: true,
    path: '/purchasing/supplier-management/supplier-po-requirements',
    name: t('Purchasing.Supplier'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.SupplierPORequirements
    ),
    permissions: [permission(STAFF_BFF, SUPPLIER_MANAGEMENT, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.SUPPLIER_MANAGEMENT,
    },
  },
  {
    exact: true,
    path:
      '/purchasing/supplier-management/supplier-po-requirements/upload-history',
    name: t('Purchasing.SupplierPORequirements'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.UploadHistory),
    permissions: [permission(STAFF_BFF, SUPPLIER_MANAGEMENT, READ)],
  },
  {
    exact: true,
    path: '/purchasing/supplier-management/supplier-po-requirements/create',
    name: t('Purchasing.CreateSupplier'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.SupplierCreate),
    permissions: [permission(STAFF_BFF, SUPPLIER_MANAGEMENT, UPDATE)],
  },
  {
    exact: true,
    path:
      '/purchasing/supplier-management/supplier-po-requirements/:supplierId',
    name: t('Purchasing.UpdateSupplier'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.SupplierPORequirementDetail
    ),
    permissions: [permission(STAFF_BFF, SUPPLIER_MANAGEMENT, UPDATE)],
  },
  {
    exact: true,
    path: '/purchasing/rebate/list',
    name: t('Purchasing.ProgramList'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseRebateUrl, PURCHASING_PATHS.RebateProgramList),
    permissions: [
      permission(REBATE_STAFF_BFF, REBATE_PROGRAM, READ),
      permission(REBATE_STAFF_BFF, REBATE_RULE, READ),
    ],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.REBATE_PROGRAM_LIST,
    },
  },
  {
    exact: true,
    path: '/purchasing/rebate/import-history',
    name: t('ImportHistory'),
    hideTitle: true,
    component: IFrameContainer,
    iFrameSrc: getSrc(baseRebateUrl, PURCHASING_PATHS.RebateImportHistory),
    permissions: [
      permission(REBATE_STAFF_BFF, REBATE_PROGRAM, READ),
      permission(REBATE_STAFF_BFF, REBATE_RULE, READ),
    ],
  },
  {
    exact: true,
    path: '/purchasing/rebate/create',
    name: t('Purchasing.ProgramCreate'),
    component: IFrameContainer,
    permissions: [
      permission(REBATE_STAFF_BFF, REBATE_PROGRAM, CREATE),
      permission(REBATE_STAFF_BFF, REBATE_RULE, CREATE),
    ],
    iFrameSrc: getSrc(baseRebateUrl, PURCHASING_PATHS.RebateProgramCreate),
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.REBATE_CREATE_PROGRAM,
    },
  },
  {
    exact: true,
    path: '/purchasing/rebate/:programId',
    name: t('Purchasing.ProgramDetail'),
    component: IFrameContainer,
    permissions: [
      permission(REBATE_STAFF_BFF, REBATE_PROGRAM, READ),
      permission(REBATE_STAFF_BFF, REBATE_RULE, READ),
    ],
    iFrameSrc: getSrc(baseRebateUrl, PURCHASING_PATHS.RebateProgramDetail),
  },
  {
    exact: true,
    path: '/purchasing/rebate/:programId/clone',
    name: t('Purchasing.ProgramCreate'),
    component: IFrameContainer,
    permissions: [
      permission(REBATE_STAFF_BFF, REBATE_PROGRAM, CREATE),
      permission(REBATE_STAFF_BFF, REBATE_RULE, CREATE),
    ],
    iFrameSrc: getSrc(baseRebateUrl, PURCHASING_PATHS.RebateProgramClone),
  },
  // hardcode routes for hiding seller switch in supply chain repo
  ...HIDE_SELLER_SWITCH_AND_BACK_BUTTON_EXPORT_TYPES.map(exportType => ({
    exact: true,
    path: `/purchasing/export-history/${exportType}`,
    name: t('ExportHistory.Root'),
    component: IFrameContainer,
    hideTitle: true,
    hideSellerSwitch: true,
    hideBackButton: true,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      `${PURCHASING_PATHS.ExportHistoryStatic}/${exportType}`
    ),
  })),
  {
    exact: true,
    path: '/purchasing/export-history/:exportType',
    name: t('ExportHistory.Root'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, EXPORT_HISTORY, READ)],
    hideTitle: true,
    hideBackButton: true,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.ExportHistory),
  },
  {
    exact: true,
    path: '/purchasing/inventory-alert',
    name: t('Purchasing.InventoryAlert'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, PURCHASING_PATHS.InventoryAlert),
    permissions: [permission(STAFF_BFF, CLEARANCE_REQUEST, READ)],
    flagKey: FLAG_KEYS.MANAGE_CLEARANCE_REQUEST,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.STOCK_ALERT,
    },
  },
  {
    exact: true,
    path: '/purchasing/inventory-alert/:bucketId/:sku',
    name: t('Purchasing.InventoryByBatch'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, PURCHASING_PATHS.InventoryTrackingByBatch),
    hideTitle: true,
    permissions: [permission(STAFF_BFF, CLEARANCE_REQUEST, READ)],
    flagKey: FLAG_KEYS.MANAGE_CLEARANCE_REQUEST,
  },
  {
    exact: true,
    path: '/purchasing/inventory-alert/action-history',
    iFrameSrc: getSrc(baseErpUrl, PURCHASING_PATHS.InventoryAlertLog),
    name: t('Purchasing.ActionHistory'),
    component: IFrameContainer,
    permissions: [permission(STAFF_BFF, AUDIT_LOG, READ_CLEARANCE_REQUEST)],
  },
  {
    exact: true,
    path: '/purchasing/stock-imbalance-alerts',
    name: t('Purchasing.StockImbalanceAlerts'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseErpUrl, PURCHASING_PATHS.StockImbalanceAlerts),
    permissions: [permission(STAFF_BFF, INVENTORY, READ_IMBALANCED_STOCKS)],
    flagKey: FLAG_KEYS.STOCK_IMBALANCE_ALERTS,
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode:
        USER_GUIDE_CODE.SUPPLY_CHAIN.ONLINE_OFFLINE_IMBALANCE_STOCK_ALERT,
    },
  },
  {
    exact: true,
    path: '/purchasing/clearance-types',
    name: t('Purchasing.ClearanceTypes'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSellerCenterV2Url, PURCHASING_PATHS.ClearanceTypes),
    permissions: [permission(STAFF_BFF, CLEARANCE_TYPE, READ)],
    guide: {
      serviceCode: SERVICE_CODE.OMNI,
      pageCode: PAGE_CODE.SUPPLY_CHAIN.SC,
      userGuideCode: USER_GUIDE_CODE.SUPPLY_CHAIN.CLEARANCE_TYPE,
    },
  },
  {
    exact: true,
    path: '/purchasing/site-lead-time/:siteId',
    name: t('Purchasing.SiteManagement'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.SiteLeadTime),
    permissions: [permission(STAFF_BFF, SITE_LEAD_TIME, READ)],
  },
  {
    exact: true,
    path: '/purchasing/action-history/:serviceCode/:actionObject/:actionOn',
    name: t('Purchasing.ActionHistory'),
    component: IFrameAuditLog,
    permissions: [permission(STAFF_BFF, AUDIT_LOG, VIEW_AUDIT_LOG_SC)],
  },
  {
    exact: true,
    path: '/purchasing/stock-transfer-tracking',
    name: t('Purchasing.StockTransferTracking'),
    component: IFrameContainer,
    hideTitle: true,
    iFrameSrc: getSrc(baseRebateUrl, PURCHASING_PATHS.StockTransferTracking),
    permissions: [permission(STAFF_BFF, STOCK_TRANSFER, READ)],
    flagKey: FLAG_KEYS.STOCK_TRANSFER_TRACKING,
  },
  {
    exact: true,
    path: '/purchasing/manufacture-management',
    name: t('Purchasing.ManufactureManagement'),
    component: IFrameContainer,
    iFrameSrc: getSrc(
      baseSupplyChainUrl,
      PURCHASING_PATHS.ManufactureManagement
    ),
    permissions: [permission(STAFF_BFF, MANUFACTURE, READ_BOM)],
    flagKey: FLAG_KEYS.MANUFACTURE_MANAGEMENT,
  },
  {
    exact: true,
    path: '/purchasing/demand-planning',
    name: t('Purchasing.DemandPlanning'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.DemandPlanning),
    permissions: [permission(STAFF_BFF, DEMAND_PLANNING, READ)],
    flagKey: FLAG_KEYS.DEMAND_PLANNING,
  },
  {
    exact: true,
    path: '/purchasing/import-history',
    name: t('ImportHistory'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.ImportHistory),
    hideTitle: true,
  },
  {
    exact: true,
    path: '/purchasing/warning',
    name: t('Purchasing.Warning'),
    component: IFrameContainer,
    iFrameSrc: getSrc(baseSupplyChainUrl, PURCHASING_PATHS.Warning),
    permissions: [
      permission(STAFF_BFF, SUPPLY_SOURCE_WARNING, READ),
      permission(STAFF_BFF, LEAD_TIME_WARNING, READ),
    ],
    flagKey: FLAG_KEYS.EPIC_OMNI_1340,
  },
];

export default purchasingRoutes;
